import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'
import { useConfirmation } from './ConfirmationContext'
import { useEscapeListener } from 'app/hooks/useEscapeListener'

export interface ModalProps {
  open: boolean
  title?: string
  subtitle?: string
  isFullScreen?: boolean
  is80Percent?: boolean
  children?: React.ReactElement | undefined | null
  showActions?: boolean
  actionLabel?: string
  closable?: boolean
  titleClassName?: string
  className?: string
  hideTitle?: boolean
  removePadding?: boolean
  overflowHidden?: boolean
  nestedModal?: React.ReactElement | undefined | null
  onActionTriggered?: () => void
  onClose: () => void
}

export function Modal({
  open,
  title,
  subtitle,
  isFullScreen = true,
  is80Percent = false,
  children,
  onClose,
  showActions = false,
  actionLabel = `ok`,
  closable = true,
  titleClassName = ``,
  className,
  hideTitle = false,
  removePadding = false,
  overflowHidden = true,
  nestedModal,
  onActionTriggered = () => {},
}: ModalProps): React.ReactElement {
  useEscapeListener(onClose, open)
  const [, isConfirmOpen] = useConfirmation()

  const handleClose = () => {
    if (isConfirmOpen) return
    onClose()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={twMerge(`dialog relative z-40 px-6`, className)} onClose={handleClose}>
        {!isFullScreen && (
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-200"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
        )}
        <div className="fixed inset-0 z-10 flex items-center justify-center">
          <div
            className={classNames(
              { 'max-h-[80%] min-h-[80%] w-[80%] items-stretch': is80Percent },
              { 'h-[100vh] w-[100vw]': isFullScreen },
              { 'items-end sm:items-center': !is80Percent },
              `flex justify-center text-center sm:p-0`,
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  isFullScreen ? `sm:w-full` : ``,
                  `flex min-h-full min-w-full flex-1 transform flex-col items-stretch rounded-lg bg-white px-5 text-left shadow-xl transition-all`,
                  { '!px-0': removePadding },
                  { 'overflow-hidden': overflowHidden },
                )}
              >
                {!hideTitle ? (
                  <div
                    className={classNames(
                      `flex h-20 shrink-0 items-center justify-between border-b border-trn-200 py-6`,
                      removePadding ? `px-6` : `pl-3 pr-6`,
                    )}
                  >
                    <div>
                      <div className={classNames(`text-heading-md capitalize leading-6 text-trn-900`, titleClassName)}>
                        {title}
                      </div>
                      {!!subtitle && <div className="text-heading-xs capitalize text-trn-600">{subtitle}</div>}
                    </div>
                    {closable && (
                      <button aria-label="close" id="close-modal-btn" onClick={handleClose} type="button">
                        <XMarkIcon className="h-5 w-5 cursor-pointer" />
                      </button>
                    )}
                  </div>
                ) : null}

                <div className={classNames(`grow`, { 'overflow-hidden': overflowHidden })}>{children}</div>

                {showActions && (
                  <div className="flex justify-end border-t border-trn-200 p-6">
                    <button aria-label="Cancel" onClick={handleClose} className="btn btn-secondary btn-md">
                      Cancel
                    </button>
                    <button onClick={onActionTriggered} className="btn btn-primary btn-md ml-4">
                      {actionLabel}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {nestedModal}
      </Dialog>
    </Transition.Root>
  )
}
